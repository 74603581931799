































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DateInput from '@/partials/forms/components/DateTime/Date.vue'
import MoneyInput from '@/partials/forms/components/MoneyInput.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    DateInput,
    MoneyInput,
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class SubscriptionTypeItemForm extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionTypeId!: string
  @Prop({ type: String }) subscriptionTypeRuleId!: string
  disableDates = false

  form: Record<string, any> = {
    name: '',
    description: '',
    validFrom: null,
    validUntil: null,
    eventSubscriptionTypeId: '',
    confirm: '',
    errors: {
      name: '',
      description: '',
      validFrom: '',
      validUntil: '',
      eventSubscriptionTypeId: '',
      confirm: ''
    }
  }

  created () {
    if (this.subscriptionTypeRuleId) {
      axios.get(`/event/${this.eventId}/subscription/type/${this.subscriptionTypeId}/rules/${this.subscriptionTypeRuleId}`)
        .then(response => {
          Object.assign(this.form, camelCaseKeys(response.data.data, { deep: true }))

          if (this.form.validFrom === null && this.form.validUntil === null) {
            this.disableDates = true
          }
        })
    } else {
      this.form.validFrom = this.form.validUntil = moment(new Date()).format('YYYY-MM-DD 12:00:00')
    }
    this.form.eventSubscriptionTypeId = this.$route.params.subscriptionId
  }

  done = true

  submit () {
    this.done = false
    let requestUrl = `/event/${this.eventId}/subscription/type/${this.subscriptionTypeId}/rules/`
    const requestVerb = this.isEditForm() ? 'put' : 'post'

    if (this.isEditForm()) {
      requestUrl += this.subscriptionTypeRuleId
    }

    const submitForm = {
      ...this.form
    }
    if (this.disableDates) {
      submitForm.validFrom = submitForm.validUntil = undefined
    }

    axios[requestVerb](requestUrl, snakeCaseKeys(submitForm, { deep: true }))
      .then(() => {
        const flashMessage: flashMessage = {
          message: `Regra ${this.isEditForm() ? 'editada' : 'cadastrada'} com sucesso.`,
          type: 'success'
        }

        this.$router.push({
          name: 'EventSubscriptionRules',
          params: {
            eventId: this.$route.params.eventId,
            subscriptionId: this.$route.params.subscriptionId
          }
        }, () => this.setFlashMessage(flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      description: '',
      validFrom: '',
      validUntil: '',
      eventSubscriptionTypeId: '',
      confirm: ''
    }
  }

  private isEditForm () {
    return !!this.subscriptionTypeRuleId
  }
}
