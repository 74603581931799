










import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'

@Component
export default class DateInput extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: String }) label!: string
  @Prop({ type: String }) minDate!: string
  @Prop({ type: String }) maxDate!: string
}
